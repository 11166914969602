import React, { useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";
import cx from "classnames";
import PropTypes from "prop-types";

import Link from "../link";
import Form from "../form";
import Post from "../../../assets/svg/post";
import Linkedin from "../../../assets/svg/linkedin";
import FaceBook from "../../../assets/svg/faceBook";

import { SHARE_LINKS_PREFIXES } from "./constants";

import * as styles from "./shareComponent.module.scss";

function ShareComponent({ isBorder }) {
  const { allSanityConfig } = useStaticQuery(graphql`
    {
      allSanityConfig {
        nodes {
          _rawSocialMedia
          form {
            _rawFormContent
          }
        }
      }
    }
  `);

  const socialType = useMemo(
    () => ({
      linkedin: <Linkedin />,
      facebook: <FaceBook />,
      post: <Post />,
    }),
    []
  );

  const socialMedia = allSanityConfig.nodes[0]._rawSocialMedia?.platform;
  const form = allSanityConfig.nodes[0].form?._rawFormContent;

  if (!socialMedia && !form) {
    return null;
  }

  return (
    <div
      className={cx({
        [styles.shareWrapper]: true,
        [styles.shareBorder]: (isBorder && socialMedia) || (isBorder && form),
      })}
    >
      {socialMedia && (
        <>
          <h3>Share</h3>
          <div className={styles.icons}>
            {socialMedia.map((media, index) => {
              const platform = media?.platform?.toLowerCase();
              const isPlatformSupported = platform in SHARE_LINKS_PREFIXES;

              return (
                <React.Fragment key={index.toString()}>
                  <Link 
                    newTabUrl={isPlatformSupported 
                      ? `${SHARE_LINKS_PREFIXES[platform]}${encodeURIComponent(window.location.href)}` 
                      : media?.url} 
                    url={isPlatformSupported 
                      ? `${SHARE_LINKS_PREFIXES[platform]}${encodeURIComponent(window.location.href)}` 
                      : media?.url}>
                    {socialType[platform]}
                  </Link>
                </React.Fragment>
              );
            })}
          </div>
        </>
      )}
      {form && (
        <Form
          bgColor={form.bgColor}
          formContent={form.formContent}
          formId={form.formId}
          portalId={form.portalId}
        />
      )}
    </div>
  );
}

ShareComponent.propTypes = {
  isBorder: PropTypes.bool,
};

ShareComponent.defaultProps = {
  isBorder: true,
};

export default ShareComponent;
